/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, ContactForm, Button } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader />

        <Column className="--style3 bg--center --full" name={"einleitung"} style={{"backgroundColor":"var(--color-supplementary)"}} fullscreen={true}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --center el--2 pb--20 pl--25 pr--25 pt--20 flex--center" anim={"2"} animS={"4"} style={{"maxWidth":1280}} columns={"2"}>
            
            <ColumnWrapper className="--left pb--16 pl--02 pr--02 pt--02" anim={null} animS={null} style={{"maxWidth":1200}}>
              
              <Title className="title-box title-box--shadow5 fs--102" content={"<span style='color: white'>Kontakt</span>"}>
              </Title>

              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-custom-1);\">+49 797 811 2X05</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box mt--02" content={"<span style=\"color: var(--color-custom-1);\">info@vase-stranky.com</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left pb--02 pl--02 pr--02 pt--02" anim={null} animS={null} style={{"maxWidth":1200}}>
              
              <Subtitle className="subtitle-box" content={"<span style='color: var(--color-dominant)'>Ivan Kräuslich</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":900}} content={"Oeder Weg 22<br>Frankfurt a/M."}>
              </Text>

              <Text className="text-box" style={{"maxWidth":900}} content={"Firmen-Ident.-Nr.: 12345678<br>Ust.-Ident. Nr. 12345678"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--60" name={"programm"}>
          
          <ColumnWrap className="column__flex el--1 pb--20 pl--25 pr--25 pt--20" style={{"maxWidth":1280}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Sitzung buchen"}>
              </Title>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textes, die Schriftgröße und den Zeilenabstand ändern und sogar die maximale Breite des Textes in Pixeln einstellen."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--0" name={"formular"}>
          
          <ColumnWrap className="column__flex --center el--1 pb--30 pl--25 pr--25 pt--20" style={{"maxWidth":1280}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <ContactForm className="--shape2 --style3 fs--20" style={{"maxWidth":1544}} action={"/contact"} fields={[{"name":"Ganze Name","type":"text","required":true,"placeholder":"Bitte tragen Sie Ihren Namen ein"},{"name":"E-Mail Kontakt:","type":"email","required":true,"placeholder":"Bitte geben Sie Ihre e-Mail Adresse ein"},{"name":"Telefon Nr.","type":"text","placeholder":"Telefon Nr."},{"name":"Nachricht","type":"textarea","required":true,"placeholder":"Bitte geben Sie Text Ihrer Nachricht ein"},{"name":"Versenden","type":"submit"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1jz76ti --style3 bg--center --full" name={"einleitung"} style={{"backgroundColor":"var(--color-supplementary)"}} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/107/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s4" anim={"7"} animS={"4"}>
              
              <Title className="title-box fs--72" content={"<span style='color: white'>Einführungskonsultation<span style='color: var(--color-variable-1);'>kostenlos</span>.</span>"}>
              </Title>

              <Subtitle className="subtitle-box fs--30 mt--20" content={"<span style='color: white'>Persönliche Trainings schon ab 10€ / St.</span>"}>
              </Subtitle>

              <Button className="btn-box btn-box--cbtn2 btn-box--filling2 fs--20 swpf--uppercase mt--30" href={"/de/kontakt"} content={"Sitzung buchen"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"partei"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--3 pb--16 pt--10" style={{"maxWidth":1200}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--24 w--600 swpf--uppercase" content={"<span style=\"color: var(--color-custom-1);\">Ivan Kräuslich</span>"}>
              </Title>

              <Subtitle className="subtitle-box fs--20 mt--02" content={"<span style='color: var(--color-dominant)'>Personal Trainer</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

              <Text className="text-box" content={"<a href=\"https://saywebpage.com\">Webproduktion stránek</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"/de/"} content={"<span style='color: var(--color-dominant)'>Einleitung</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"/de/dienstleistungen"} content={"<span style='color: var(--color-dominant)'>Dienstleistungen</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"/de/referenzen"} content={"<span style='color: var(--color-dominant)'>Referenzen</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"/de/kontakt"} content={"<span style='color: var(--color-dominant)'>Kontakt</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}